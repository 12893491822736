.message {
    color: white;
    padding: 10px 0;
    font-family: 'SpaceGrotesk', sans-serif;
}

.warning {
    color: #ff6252;
}

.success {
    color: #2ac670;
}